





































import Vue from "vue";
import { Action, Getter } from "vuex-class";
import { Presentation } from "@/core/models";
import { Component, Prop, PropSync } from "vue-property-decorator";

type Item = { name: string; value: Presentation | null };
@Component
export default class PresentationSelect extends Vue {
  @Prop({ default: () => false }) disabled!: boolean;
  @PropSync("selectedProp", { default: () => null })
  selected!: Presentation | null;

  @Getter("presentations/loading") loading!: boolean;
  @Getter("presentations/retrieved") retrieved!: boolean;
  @Getter("presentations/getPresentations") presentations!: Presentation[];
  @Action("presentations/getPresentations")
  getPresentations!: () => Promise<void>;
  @Action("displaySnackbar") displaySnackbar!: (s: string) => void;

  dialog = false;
  get items() {
    const items: Item[] = this.presentations.map(p => ({
      name: `${p.File.Name}`,
      value: p,
    }));
    items.unshift({ name: this.$t("global.none").toString(), value: null });
    return items;
  }
  get name() {
    if (!this.selected) return this.$t("upsess.select").toString();
    return this.selected.File.Name;
  }
  openSelect() {
    if (!this.presentations.length)
      this.displaySnackbar(this.$t("pres.none").toString());
    else this.dialog = true;
  }
  selectPresentation(pres: Presentation | null) {
    this.dialog = false;
    this.selected = pres;
  }

  created() {
    if (!this.retrieved) this.getPresentations();
  }
}
