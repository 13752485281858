import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"mt-4",attrs:{"outlined":""}},[_c(VCardTitle,{staticClass:"d-flex"},[_c('div',{staticClass:"text-h5 flex-1 mr-4 font-weight-bold main--text"},[_vm._v(" "+_vm._s(_vm.$t("rec.latest"))+" ")]),_c(VBtn,{attrs:{"loading":_vm.loading,"icon":""},on:{"click":_vm.getRecords}},[_c(VIcon,[_vm._v("mdi-refresh")])],1)],1),_c(VCardText,[_c('div',{attrs:{"data-tour":"list"}},[_c('div',{staticClass:"flex-1"},[(_vm.loading)?_c('div',{staticClass:"pa-4 d-flex",staticStyle:{"justify-content":"center"}},[_c(VProgressCircular,{attrs:{"indeterminate":"","size":"32","color":"main"}})],1):_vm._e(),(_vm.recordList.length > 0 && !_vm.loading)?_c('div',{staticStyle:{"max-height":"400px","overflow":"auto","padding":"0px 10px"}},_vm._l((_vm.recordList),function(r){return _c('RecordingItem',{key:r.id,attrs:{"recording":r},on:{"dialogToggled":function (v) { return _vm.$emit('dialogToggled', v); },"remove":function () { return _vm.removeRecording(r); },"edit":function (data) { return _vm.editRecording(r, data); }}})}),1):_vm._e()])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }