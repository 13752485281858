







































import Vue from "vue";
import { Component, Prop, PropSync, Watch } from "vue-property-decorator";

import api from "@/core/utils/api";
import Pages from "@/core/utils/Pages";
import { ApiRecording, Recording } from "@/core/models";
import PagesArrows from "@/components/common/PagesArrows.vue";
import RecordingItem from "./RecordingItem.vue";

@Component({ components: { RecordingItem, PagesArrows } })
export default class RecordList extends Vue {
  @Prop({ default: () => "Recordings" }) title!: string;

  loading = false;
  @PropSync("records", { default: () => [] }) recordList!: Recording[];

  recordCreated(r: Recording) {
    this.recordList.unshift(r);
    this.setPages();
  }
  editRecording(r: Recording, data: { title?: string; locale?: "en" | "de" }) {
    const idx = this.recordList.findIndex(x => x.id === r.id);
    if (idx === -1) return;
    const { title, locale } = data;

    if (title) this.recordList[idx].title = title;
    if (locale) this.recordList[idx].locale = locale;
    this.setPages();
  }
  removeRecording(r: Recording) {
    const idx = this.recordList.findIndex(x => x.id === r.id);
    if (idx === -1) return;
    this.recordList.splice(idx, 1);
    this.setPages();
  }

  async getRecords() {
    this.loading = true;
    const apiRecords = (await api.get("/api/Recordings")) as ApiRecording[];
    const records = apiRecords.map(x => {
      const recording: Recording = {
        id: x.id,
        blob: null,
        audioBlob: null,
        videoBlob: null,
        title: x.title,
        locale: x.locale,
        audioURI: x.audioURI,
        videoURI: x.videoURI,
        recordedAt: x.recordedAt,
        timestamps: x.timestamps,
        type: x.type,
      };
      return recording;
    });
    this.recordList = [...records];
    this.setPages();
    this.loading = false;
  }

  mounted() {
    this.getRecords();
  }

  @Prop({ default: () => 5 }) limit!: number;
  pages: Pages<Recording> = Pages.Empty();
  get recordsPage() {
    return this.pages.CurrentPage;
  }
  setPages() {
    this.pages = new Pages<Recording>(this.limit, this.recordList);
  }
  @Watch("recordList", { immediate: true })
  recordListUpdated() {
    this.setPages();
  }
}
