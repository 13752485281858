import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"list-item",on:{"click":function($event){_vm.dialog = true}}},[_c('div',{staticClass:"list-avatar"},[_c(VIcon,[_vm._v(" "+_vm._s(_vm.recording.type === "video" ? "mdi-video" : "mdi-microphone")+" ")])],1),_c('div',{staticClass:"list-content"},[_c('div',{staticClass:"list-title"},[_vm._v(_vm._s(_vm.title))]),_c('div',{staticClass:"list-subtitle"},[_c('span',[_vm._v(_vm._s(_vm._f("format")(_vm.recording.recordedAt)))])])]),_c(VSpacer),_c('div',[_c(VIcon,[_vm._v("mdi-earth")]),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm.lang))])],1),_c(VSpacer),_c('div',{staticClass:"list-actions"},[_c(VDialog,{attrs:{"max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({attrs:{"icon":"","loading":_vm.removing}},on),[_c(VIcon,[_vm._v("mdi-delete")])],1)]}}]),model:{value:(_vm.removeDialog),callback:function ($$v) {_vm.removeDialog=$$v},expression:"removeDialog"}},[_c(VCard,[_c(VCardTitle,[_c('span',[_vm._v(_vm._s(_vm.$t("rec.remove")))])]),_c(VCardText,[_c('div',[_vm._v(_vm._s(_vm.$t("rec.removetext")))]),_c('div',{staticClass:"font-weight-bold main--text"},[_c('span',[_vm._v(_vm._s(_vm.recording.title))])])]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"text":""},on:{"click":function($event){_vm.removeDialog = false}}},[_c('span',[_vm._v(_vm._s(_vm.$t("global.cancel")))])]),_c(VBtn,{attrs:{"color":"primary","elevation":"0"},on:{"click":_vm.removeRecord}},[_c('span',[_vm._v(_vm._s(_vm.$t("global.confirm")))])])],1)],1)],1)],1)],1),_c('RecordingDialog',{attrs:{"dialog-prop":_vm.dialog,"recording":_vm.recording},on:{"update:dialogProp":function($event){_vm.dialog=$event},"update:dialog-prop":function($event){_vm.dialog=$event},"edit":_vm.edit}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }