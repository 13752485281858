


















































































































































































































































































































































































































































































import Vue from "vue";
import { Action, Getter } from "vuex-class";
import { Component, Prop, PropSync, Watch } from "vue-property-decorator";

import LocalStorage from "@/core/utils/LocalStorage";
import ImageSlider from "@/components/common/ImageSlider.vue";
import {
  LookupWord,
  Pause,
  Presentation,
  Recording,
  RoleClaims,
  Session,
  Timestamp,
  TranscriptionSegment,
  TranscriptionSlide,
  UserClaims,
  WordSegment,
} from "@/core/models";
import {
  RecordList,
  PresentationSelect,
} from "@/views/liverecorder/components";
import VolumeTester from "@/views/recorder/components/volume-tester";
import LangSwitcher from "@/views/recorder/components/LangSwitcher.vue";
import Recorder from "@/core/utils/videoRecorder";
import { LiveTranscription } from "@/core/utils/transcription";
import { Analyzer } from "@/core/utils/analyzer";
import { convertDuration } from "@/views/recorder/components/utils";
import api from "@/core/utils/api";
import VolumeGauge from "@/views/recorder/components/volume-tester/Gauge.vue";
import DeviceSelect from "@/views/recorder/components/DeviceSelect.vue";
import WPMCard from "@/components/sessions/cards/stateless/WPMCard.vue";
import PausesCard from "@/components/sessions/cards/stateless/PausesCard.vue";
import VoicePitch from "@/components/sessions/cards/stateless/VoicePitch.vue";
import LookupWords from "@/components/sessions/cards/stateless/LookupWords.vue";
import { recordingSettings } from "@/settings";
import { getDevices, selectDevice, Device } from "@/views/recorder/lib/devices";

@Component({
  methods: { convertDuration },
  computed: {
    LocalStorage() {
      return LocalStorage;
    },
    recordingSettings() {
      return recordingSettings;
    },
  },
  components: {
    LookupWords,
    VoicePitch,
    PausesCard,
    WPMCard,
    DeviceSelect,
    VolumeGauge,
    ImageSlider,
    LangSwitcher,
    RecordList,
    VolumeTester,
    PresentationSelect,
  },
})
export default class LiveRecorder extends Vue {
  //TODO convert to locals
  /* Video Recorder Props */
  @Prop({ default: () => true }) isTimed!: boolean;
  @Prop({ default: () => 12 * 60 }) expectedLen!: number;
  @Prop({ default: () => 14 * 60 }) warningLen!: number;
  @Prop({ default: () => 15 * 60 }) maxLen!: number;
  @Prop({ default: () => false }) loading!: boolean;
  /* -------------------- */

  @Getter("sessions/sessionsCount") sessionsCount!: number;
  @Getter("profile/showTutorial") showTutorial!: boolean;
  @Getter("profile/tutorialStep") tutorialStep!: number;
  @Getter("profile/permissions") permissions!: RoleClaims[];
  @Getter("profile/userClaims") userClaims!: UserClaims[];
  @PropSync("isRecordingProp") isRecording!: boolean;
  @Action("displaySnackbar") displaySnackbar!: (m: string) => void;
  @Action("presentations/getOne") getOne!: (
    id: number,
  ) => Promise<Presentation>;
  @Action("sessions/addSession") addSession!: (s: Session) => void;
  @Action("profile/fetchPermissions") fetchPermissions!: () => Promise<void>;

  @Getter("presentations/retrieved") retrieved!: boolean;
  @Action("presentations/getPresentations")
  getPresentations!: () => Promise<void>;

  get analysisFastQuota() {
    if (this.userClaims) {
      const quotaClaim = this.userClaims.filter(
        x => x.type === "analysis:fast",
      );
      if (quotaClaim[0]) {
        if (parseInt(quotaClaim[0].value as string, 10) === -1)
          return "unlimited";

        return parseInt(quotaClaim[0].value as string, 10);
      }
      return 0;
    }
    return 0;
  }

  get analysisRelaxedQuota() {
    if (this.userClaims) {
      const quotaClaim = this.userClaims.filter(
        x => x.type === "analysis:relaxed",
      );
      if (quotaClaim[0]) {
        if (parseInt(quotaClaim[0].value as string, 10) === -1)
          return "unlimited";

        return parseInt(quotaClaim[0].value as string, 10);
      }
      return 0;
    }
    return 0;
  }

  get liveTranscriptionFastQuota() {
    if (this.userClaims) {
      const quotaClaim = this.userClaims.filter(
        x => x.type === "live_transcription:fast",
      );
      if (quotaClaim[0]) {
        if (parseInt(quotaClaim[0].value as string, 10) === -1)
          return "unlimited";

        return parseInt(quotaClaim[0].value as string, 10);
      }
      return 0;
    }
    return 0;
  }

  get liveTranscriptionRelaxedQuota() {
    if (this.userClaims) {
      const quotaClaim = this.userClaims.filter(
        x => x.type === "live_transcription:relaxed",
      );
      if (quotaClaim[0]) {
        if (parseInt(quotaClaim[0].value as string, 10) === -1)
          return "unlimited";

        return parseInt(quotaClaim[0].value as string, 10);
      }
      return 0;
    }
    return 0;
  }

  // components
  recorder: Recorder = new Recorder();
  analyzer: Analyzer = new Analyzer();
  transcription = new LiveTranscription(() => {
    //console.log("Transcription done!");
    // run custom action when recognize is done.
  });

  // objects
  pres: Presentation | null = null;
  recording: Recording | null = null;
  selectedPresentation: Presentation | null = null;
  presentation: Presentation | null = this.selectedPresentation;
  records: Recording[] = [];
  segments: TranscriptionSegment[] | null = null;
  slides: TranscriptionSlide[] | undefined = [];

  // video recorder
  video: HTMLVideoElement | undefined;
  blob: Blob | undefined;
  blobs:
    | { videoBlob: Blob | undefined; audioBlob: Blob | undefined }
    | undefined;
  isTooBig = false;
  isPaused = false;
  isActive = false;
  flipped = false;

  // time controls
  elapsedTime = 0;
  interval: any = null;
  timeout: any = null;

  // Tester
  stopTester = false;

  // Analyzer
  analyzerReady = false;

  // pitch
  currentPitch = 0;
  currentNote = "";
  pitchAnalysis: { time: number; value: number }[] | any[] = [];
  avgPitch: number | undefined;

  // volume
  currentVolume = 0;
  volumeAnalysis: { time: number; value: number }[] | any[] = [];
  avgVolume: number | undefined;

  // pauses
  pauseAnalysis: Pause[] = [];
  avgPauseLength = 0;
  totalPauseTime = 0;
  pausesMade = 0;

  // text transcription
  isTranscribing = false;
  completeText = "";
  previousText = "";
  newText = "";

  capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  // wpm
  displayLiveWpm = 0;
  wpmInterval: any = null;
  liveWpm = 0;

  // Animate wpm change via watcher and computed prop
  get computedLiveWpm() {
    return this.liveWpm;
  }
  set computedLiveWpm(wpm: number) {
    this.liveWpm = wpm;
  }

  @Watch("liveWpm")
  liveWpmChanged() {
    clearInterval(this.wpmInterval);
    if (this.computedLiveWpm === this.displayLiveWpm) return;

    const transitionDuration = 1000;
    const transitionRefreshRate = 20;

    this.wpmInterval = setInterval(() => {
      if (!this.isPaused)
        if (
          Math.floor(this.displayLiveWpm) !== Math.floor(this.computedLiveWpm)
        ) {
          let change =
            (this.computedLiveWpm - this.displayLiveWpm) / transitionDuration;
          change = change >= 0 ? Math.ceil(change) : Math.floor(change);
          this.displayLiveWpm = this.displayLiveWpm + change;
        } else {
          this.displayLiveWpm = this.computedLiveWpm;
          clearInterval(this.wpmInterval);
        }
    }, transitionRefreshRate);
  }

  // misc
  lang = "de";
  mode = "video";
  url: string | null = null;
  audioUrl: string | null = null;
  videoUrl: string | null = null;
  tipDialog = false;
  showVolume = false;

  // recorder locale
  recorderLocale = this.$i18n.locale as "en" | "de";

  devices: Device[] = [];
  selectedDevice: string | null = null;

  @Watch("$i18n.locale", { immediate: true })
  langChanged() {
    this.recorderLocale = this.$i18n.locale as "en" | "de";
  }
  @Watch("presentation")
  selectedChanged() {
    this.slideIndex = 0;
    this.addSlide();
  }
  @Watch("selectedPresentation")
  presentationChanged() {
    this.presentation = this.selectedPresentation;
  }
  @Watch("$route", { immediate: true })
  async routeChanged() {
    const { /* tour, */ presentation } = this.$route.query;

    // check slides retrieved
    if (!this.retrieved) await this.getPresentations();

    // check pres
    const id = Number(presentation);
    if (!isNaN(id)) this.selectedPresentation = await this.getOne(id);

    // check tour
    //if (tour === "true" && this.showTutorial && this.tutorialStep === 3)
    //  this.startTour();
  }
  startTimer() {
    this.interval = setInterval(() => {
      if (!this.isPaused) this.elapsedTime += 0.1;
    }, 100);
  }
  stopTimer() {
    clearInterval(this.interval);
  }
  startTimeout() {
    this.timeout = setTimeout(
      this.stop,
      (this.maxLen - this.elapsedTime) * 1000,
    );
  }
  stopTimeout() {
    clearTimeout(this.timeout);
  }

  async start() {
    if (this.devices.length === 0) {
      this.displaySnackbar(this.$t("recording.noCompatibleDevice").toString());
      return;
    }

    this.selectedDevice = this.selectedDevice || this.devices[0]?.id;

    if (!this.video || !this.recorder) {
      console.error("Video element or Videorecorder not available.");
      return;
    }

    this.reset();

    this.isRecording = true;

    this.timestamps.push({
      time: 0,
      index: 0,
      slideIndex: this.slideIndex,
      slideURI: this.images[this.slideIndex],
    });

    this.durationInterval = setInterval(() => {
      if (!this.isPaused) this.duration += 1;
    }, 1000);

    try {
      // Start recording => analyzer, and transcription parallel
      await Promise.all([
        this.startRecorder(true).then(async () => {
          await this.startAnalyzer().then(() => {
            this.analyzerReady = true;
          });
        }),
        this.startTranscription(this.selectedDevice as string),
      ]);
    } catch (error) {
      console.error("Error during startup:", error);
      return;
    }

    if (this.recorder.stream)
      this.video.srcObject = this.recorder.stream as MediaStream;
    else console.error("Recorder stream is undefined.");

    try {
      await this.video.play();
    } catch (error) {
      console.error("Error playing video:", error);
      return;
    }

    this.video.muted = true;
    this.video.controls = false;
    this.isActive = true;
    this.startTimer();

    if (this.isTimed) this.startTimeout();
  }

  async stop() {
    try {
      this.isTranscribing = false;

      const result = await this.recorder.stop();
      if (!result) return;

      const { videoBlob, audioBlob } = result;
      const blob = videoBlob;
      this.blobs = { videoBlob, audioBlob };

      // Handle video blob (if needed)
      if (videoBlob) this.videoUrl = URL.createObjectURL(videoBlob);

      // Handle audio blob (if needed)
      if (audioBlob) this.audioUrl = URL.createObjectURL(audioBlob);

      this.isRecording = false;
      this.isPaused = false;
      if (this.isTimed) this.stopTimeout();
      this.stopTimer();
      this.elapsedTime = 0;
      this.shouldReset = true;

      // clear duration
      clearInterval(this.durationInterval);

      // clear analyzer
      await this.analyzer.stop();

      // get slides
      const { slides, segments, pauses } = await this.transcription.stop();

      this.slides = slides;
      this.segments = segments;
      this.pauseAnalysis = pauses;

      if (this.slides && this.slides?.length > 0)
        // attach analyzer values and images to slides
        // TODO: Replace timestamps with slides duration/offset
        for (let i = 0; i < this.timestamps.length; i++) {
          // slide times
          const currTime = this.timestamps[i].time;
          const nextTime =
            i + 1 >= this.timestamps.length
              ? (this.duration + 1) * 1000
              : this.timestamps[i + 1].time;

          console.log(
            "[slide times]",
            Math.round(currTime),
            Math.round(nextTime),
          );

          // slide pitches & volumes
          const pitchValues = this.pitchAnalysis.filter(
            x => x.time >= currTime && x.time < nextTime,
          );
          const pitchSum = pitchValues
            .map(x => x.value)
            .reduce((cum, cur) => cum + cur, 0);

          const volumeValues = this.volumeAnalysis.filter(
            x => x.time >= currTime && x.time < nextTime,
          );
          const volumeSum = volumeValues
            .map(x => x.value)
            .reduce((cum, cur) => cum + cur, 0);

          // slide value assignment
          this.slides[i].avgPitch = Math.round(
            pitchSum / pitchValues.length,
          ) as number;
          this.slides[i].avgVolume = Math.round(
            (volumeSum * 100) / volumeValues.length,
          ) as number;
          this.slides[i].image = this.images[this.timestamps[i].slideIndex];
        }

      // average pitch
      this.avgPitch = Math.round(
        this.pitchAnalysis
          .map((x: any) => x.value)
          .reduce((cum, cur) => cum + cur, 0) / this.pitchAnalysis.length,
      );

      // average volume
      this.avgVolume = Math.round(
        (this.volumeAnalysis
          .filter((x: any) => x.value > 0)
          .map((x: any) => parseFloat(x.value))
          .reduce((cum, cur) => cum + cur, 0) *
          100) /
          this.volumeAnalysis.length,
      );

      // average pause length
      this.totalPauseTime = this.pauseAnalysis
        .map((x: any) => x.duration)
        .reduce((cum, cur) => cum + cur, 0);
      const totalPauses = this.pauseAnalysis.length;
      this.avgPauseLength = Math.round(
        this.totalPauseTime / totalPauses / 1000,
      );
      if (isNaN(this.avgPauseLength)) this.avgPauseLength = 0;

      // create recording
      const now = new Date();
      this.recording = {
        id: 0,
        blob,
        audioBlob: audioBlob,
        videoBlob: videoBlob,
        type: this.mode as any,
        locale: this.lang as any,
        timestamps: this.timestamps,
        recordedAt: now.toISOString(),
        presentationId: this.presentation?.ID,
        title: `recording_${now.toLocaleDateString()}`,

        wpm: this.totalWpm,
        slides: this.slides,
        duration: this.duration,
        text: this.completeText,
      };

      const videoPlayback = document.querySelector(
        "#video-playback",
      ) as HTMLVideoElement;

      if (videoPlayback && this.videoUrl) {
        videoPlayback.src = this.videoUrl;
        videoPlayback.playsInline = true;
        videoPlayback.controls = true;
        videoPlayback.muted = false;
      }

      // Update quota delayed to be sure to catch the recent value with possible response lags
      setTimeout(async () => {
        await this.fetchPermissions();
      }, 500);

      // Reset recording if no text was recognized
      if (!this.completeText) {
        this.displaySnackbar(this.$t("upsessv.noText").toString());
        this.reset();
      }

      console.debug(`
LIVE-TRANSCRIPT-RESULTS
-----------------------

Voice pitch:
${this.currentPitch}Hz
Note:
${this.currentNote}
Volume:
${Math.round(this.currentVolume * 100)}%

Pitch over time:
${JSON.stringify(this.pitchAnalysis)}
Volume over time:
${JSON.stringify(this.volumeAnalysis)}
Pauses over time:
${JSON.stringify(this.pauseAnalysis)}
Average pitch:
${this.avgPitch}Hz
Average volume:
${this.avgVolume}%
Average pause length:
${this.avgPauseLength}s
Pauses made:
${this.pauseAnalysis.length}
Total pause time:
${this.pauseTimeTotal}s
Pauses per minute:
${this.pausesPerMinute}

Text:
${this.completeText}
Transcription Duration:
${this.durationFormatted}
Words Per Minute:
${this.totalWpm}
Words:
${JSON.stringify(this.totalWords)}
Lookup Words:
${JSON.stringify(this.totalLookupWords)}

Slides:
${JSON.stringify(this.slides)}
      `);
    } catch (error) {
      console.error("Error stopping recording:", error);
    }
  }

  reset() {
    if (this.isPaused) this.togglePause();
    if (this.running) this.stop();
    this.recorder.reset();
    this.isRecording = false;
    this.isActive = false;
    this.duration = 0;
    this.slideIndex = 0;
    this.timestamps = [];
    this.shouldReset = true;
    this.url = null;
    this.videoUrl = null;
    this.audioUrl = null;
    this.slides = undefined;
    this.recording = null;
    this.pitchAnalysis = [];
    this.volumeAnalysis = [];
    this.pauseAnalysis = [];
    this.avgPitch = undefined;
    this.avgVolume = undefined;
    this.avgPauseLength = 0;
    this.totalPauseTime = 0;
    this.stopTimer();
    this.stopTimeout();
    this.elapsedTime = 0;
    this.isTranscribing = false;
    this.completeText = "";
    this.previousText = "";
    this.newText = "";
    this.displayLiveWpm = 0;
    this.computedLiveWpm = 0;

    if (!this.video) return;
    this.video.muted = true;
    this.video.currentTime = 0;
    this.video.removeAttribute("src");
    this.video.removeAttribute("srcObject");
    this.video.load();
  }
  // muting/gain
  muted = false;
  toggleMuted() {
    this.recorder?.toggleMuted();
    this.muted = !this.muted;
  }

  slideChanged(idx: number) {
    this.slideIndex = idx;
    this.addSlide();
  }

  // slide getters
  get totalWpm() {
    if (!this.slides) return 0;
    const wpm =
      this.slides.map(x => x.wpm).reduce((cum, cur) => cum + cur, 0) /
      this.slides.length;
    return Math.round(wpm * 100) / 100;
  }

  get totalWords() {
    const words: WordSegment[] = [];
    this.slides?.map(slide => words.push(...(slide.words as WordSegment[])));

    return words;
  }

  get totalLookupWords() {
    const lookupWords: LookupWord[] = [];
    this.slides?.map(slide => {
      slide.lookupWords.map(word => {
        if (lookupWords.some(lookupWord => lookupWord.text === word.text))
          lookupWords[
            lookupWords.findIndex(lookupWord => lookupWord.text === word.text)
          ].occurrences++;
        else lookupWords.push(word);
      });
    });

    return lookupWords;
  }

  // duration stuff
  duration = 0;
  durationInterval: any;
  get durationFormatted() {
    if (this.running || this.recording)
      return convertDuration(Math.round(this.duration));
    else return convertDuration(0);
  }

  get pauseTimeTotal(): number {
    return Math.round(this.totalPauseTime / 1000);
  }

  get pausesPerMinute(): number {
    return (
      (Math.round((this.pauseAnalysis.length / this.duration) * 60) / 10) * 10
    );
  }

  get running() {
    return this.recorder.isRecording && this.transcription.running;
  }

  // recording button style
  get buttonStyle() {
    const border = `border: 1px solid ${this.running ? "transparent" : "red"}`;
    const bgColor = `background-color: ${this.running ? "red" : "transparent"}`;
    return [border, bgColor].join(";");
  }

  uploading = false;
  askForFeedback = false;
  async upload() {
    if (!this.recording) return;

    this.uploading = true;

    try {
      const now = new Date();
      const name = `${this.$t("rec.prefix")} ${now.toLocaleString()}`;

      // create data object
      const data = new FormData();
      data.append("type", this.recording.type);
      data.append("audioFile", this.recording.audioBlob || null);
      data.append("videoFile", this.recording.videoBlob || null);
      data.append("title", name);
      data.append("locale", this.recording.locale);
      data.append("wpm", this.recording.wpm!.toString());
      data.append("text", JSON.stringify(this.recording.text));
      data.append("words", JSON.stringify(this.totalWords));
      data.append("lookupWords", JSON.stringify(this.totalLookupWords));
      data.append("slides", JSON.stringify(this.recording.slides));
      data.append(
        "duration",
        JSON.stringify(Number(this.recording.duration!.toFixed(2))),
      );
      data.append("timestamps", JSON.stringify(this.recording.timestamps));
      data.append("recordedAt", this.recording.recordedAt);
      data.append("pitchAnalysis", JSON.stringify(this.pitchAnalysis));
      data.append("avgPitch", (this.avgPitch || 0).toString());
      data.append("volumeAnalysis", JSON.stringify(this.volumeAnalysis));
      data.append("avgVolume", (this.avgVolume || 0).toString());
      data.append("pauseAnalysis", JSON.stringify(this.pauseAnalysis));
      data.append("avgPauseLength", this.avgPauseLength.toString());
      data.append("totalPauseTime", (this.pauseTimeTotal || 0).toString());
      data.append("pausesPerMinute", (this.pausesPerMinute || 0).toString());
      data.append("transcribedSegments", JSON.stringify(this.segments));
      if (this.presentation)
        data.append(
          "presentationId",
          this.recording.presentationId?.toString() || "0",
        );

      // send to api
      const session = (await api.post("/api/LiveTranscription/New", data, {
        headers: { "Content-Type": "multipart/form-data" },
      })) as Session;
      this.addSession(session);
    } catch (error) {
      console.error(error);
    }
    this.uploading = false;
    this.reset();

    await this.$router.push("/sessions/list");
  }

  togglePause() {
    if (this.isPaused) {
      this.isPaused = false;
      this.recorder.resume();
      this.transcription.resume();
      this.startTimer();
      if (this.isTimed) this.startTimeout();
    } else {
      this.isPaused = true;
      this.recorder.pause();
      this.transcription.pause();
      this.stopTimer();
      if (this.isTimed) this.stopTimeout();
      clearInterval(this.wpmInterval);
    }
  }

  async refreshDevices() {
    this.devices = await getDevices("audio");
    this.selectedDevice = LocalStorage.getInputDevice();
  }

  async selectInputDevice(deviceId: string) {
    selectDevice(deviceId, "audio");
    await this.refreshDevices();
  }

  addSlide() {
    this.transcription.addSlide();
    this.timestamps.push({
      //time: this.duration,
      time: Math.round(this.elapsedTime * 1000),
      slideIndex: this.slideIndex,
      index: this.transcription.slide,
      slideURI: this.images[this.slideIndex],
    });
  }

  handleButtonClick() {
    if (this.isPaused) this.togglePause();
    else if (LocalStorage.getRecordLimitDialog())
      this.toggleRecordLimitWarning(!this.isRecordLimitWarning, () =>
        this.start(),
      );
    else this.start();
  }

  async startRecorder(includeVideo: boolean) {
    try {
      await this.recorder.start(
        {
          onError: (errorMessage: string) => this.displaySnackbar(errorMessage),
        },
        includeVideo,
      );
    } catch (error) {
      console.error("Error starting recorder:", error);
      throw error;
    }

    if (!this.recorder.stream) {
      console.error("Failed to start recording stream.");
      throw new Error("Recording stream not started.");
    }
  }

  async startAnalyzer() {
    try {
      if (this.recorder.stream)
        await this.analyzer.start(this.recorder.stream, data => {
          if (!data) throw "Analyzer not ready";

          if (data.volume > 0) {
            this.currentVolume = data.volume;
            this.volumeAnalysis.push({ time: data.time, value: data.volume });
          }
          if (data.pitch >= 50 && data.pitch <= 600) {
            this.currentPitch = Math.round(data.pitch);
            this.currentNote = data.note;
            this.pitchAnalysis.push({ time: data.time, value: data.pitch });
          }
        });
      else console.error("Recorder stream is undefined.");
    } catch (error) {
      console.error("Error starting analyzer:", error);
      throw error;
    }
  }

  async startTranscription(selectedDeviceId: string) {
    try {
      await this.transcription.start(
        this.recorder.stream,
        this.lang === "en" ? "en-US" : "de-DE",
        selectedDeviceId,
        (text, isTranscribing, segment, slide, pauses) => {
          this.isTranscribing = isTranscribing;

          // Handle initial recognition, before first segment or slide is completed
          if (!segment || !slide) {
            this.completeText = text
              .replace(/\r?\n?[^\r\n]*$/, "")
              .replaceAll("\r\n", " ");
            this.previousText = this.completeText;

            return;
          }

          // Pauses calculations
          if (pauses && pauses.length > 0) this.pauseAnalysis = pauses;

          this.totalPauseTime = this.pauseAnalysis
            .map(x => x.duration)
            .reduce((cum, cur) => cum + cur, 0);
          const totalPauses = this.pauseAnalysis.length;
          this.avgPauseLength = Math.round(
            this.totalPauseTime / totalPauses / 1000,
          );
          if (isNaN(this.avgPauseLength)) this.avgPauseLength = 0;

          // Get all values for the followup recognition callbacks upon first slide create
          this.computedLiveWpm = slide.wpm;
          this.previousText = this.completeText;
          this.completeText = text
            .replace(/\r?\n?[^\r\n]*$/, "")
            .replaceAll("\r\n", " ");
          this.newText = segment.text;

          // Re-trigger css blink animation
          const liveSentence = document.getElementById("liveSentence");
          liveSentence?.classList.remove("text-blink");
          liveSentence?.classList.add("font-weight-black");
          void liveSentence?.offsetWidth; // Trigger reflow
          liveSentence?.classList.add("text-blink");
          setTimeout(() => {
            liveSentence?.classList.remove("font-weight-black");
          }, 850);
        },
      );
    } catch (error) {
      console.error("Error starting transcription:", error);
      throw error;
    }
  }

  closeTip() {
    //if (this.dontShowTip) LocalStorage.setShouldShowRecordingTip(false);
    //this.dontShowTip = true;
    this.tipDialog = false;
  }

  // Slides
  tsIndex = 0;
  slideIndex = 0;
  shouldReset = false;
  timestamps: Timestamp[] = [];

  get images() {
    if (!this.presentation) return [];
    return this.presentation.Slides.map(x => x.Uri);
  }
  get maxWidth() {
    return undefined;
  }
  get maxHeight() {
    return undefined;
  }
  created() {
    window.onbeforeunload = () => {
      if (this.running)
        return "The recording is still running... Are you sure you wanna exit?";
    };
  }
  mounted() {
    this.video = document.querySelector("#video") as HTMLVideoElement;
    this.video.controls = false;

    this.showVolume = true;
    const show = LocalStorage.getShouldShowRecordingTip();
    if (show && this.$route.query.tour !== "true")
      this.$nextTick(() => (this.tipDialog = true));
    document.documentElement.classList.add("no-scroll");

    this.refreshDevices();

    void this.fetchPermissions();
  }

  beforeDestroy() {
    //this.stop();
    window.onbeforeunload = null;
  }

  handleUpload() {
    if ((this.recording?.duration ?? 0) < recordingSettings.durationThreshold)
      this.toggleShortRecordWarning(true);
    else this.upload();
  }

  confirmUpload() {
    this.upload();
    this.toggleShortRecordWarning(false);
  }

  // dialogs: recording duration
  isShortRecordWarning = false;

  toggleShortRecordWarning(visible: boolean) {
    this.isShortRecordWarning = visible;
  }

  // dialogs: record limit
  isRecordLimitWarning = false;
  recordLimitCallback: () => void = () => null;
  recordLimitWarningChecked = !LocalStorage.getRecordLimitDialog();

  setRecordLimitWarningChecked(checked: boolean) {
    LocalStorage.setRecordLimitDialog(!checked);
    this.recordLimitWarningChecked = checked;
  }

  toggleRecordLimitWarning(visible: boolean, confirmationCallback: () => void) {
    this.recordLimitWarningChecked = !LocalStorage.getRecordLimitDialog();
    this.isRecordLimitWarning = visible;
    this.recordLimitCallback = confirmationCallback;
  }
}
