export type Status = {
  time: number;
  pitch: number;
  note: string;
  volume: number;
};

export class Analyzer {
  private context: AudioContext | null;
  private source: MediaStreamAudioSourceNode | null;
  private analyserNode: AnalyserNode | null;

  private readonly sampleRate: number;
  private readonly intBuffer: Uint8Array;
  private readonly buffer: Float32Array;

  // update interval
  private intervalLength = 100;
  private interval: any;

  async start(stream?: MediaStream, cb: (data: Status) => void = () => {}) {
    console.log("AudioContext state: ", this.context?.state);

    // Create new audioContext if it doesn't exist or is already closed
    if (!this.context || this.context.state === "closed") {
      this.context = new AudioContext();
      console.log("New AudioContext created with state:", this.context?.state);
    }

    // Resume audioContext if it was suspended
    if (this.context.state === "suspended") {
      await this.context.resume();
      console.log("AudioContext resumed with state:", this.context?.state);
    }

    // Stream
    if (!stream)
      stream = await navigator.mediaDevices.getUserMedia({
        audio: {
          channelCount: 1,
          echoCancellation: true,
          sampleRate: this.sampleRate,
        },
        video: false,
      });

    // source
    this.source = this.context.createMediaStreamSource(stream);

    // analyzer
    this.analyserNode = this.context.createAnalyser();
    this.analyserNode.fftSize = this.sampleRate;
    this.analyserNode.minDecibels = -127;
    this.analyserNode.maxDecibels = 0;
    this.analyserNode.smoothingTimeConstant = 0.1;

    // node network
    this.source.connect(this.analyserNode);

    // interval
    let time = 0;
    this.interval = setInterval(() => {
      const volume = this.getVolume();

      cb({ ...this.getPitch(), time, volume });
      time += this.intervalLength;
    }, this.intervalLength);
  }

  async stop() {
    await this.context?.close();
    this.source = null;
    this.analyserNode = null;
    clearInterval(this.interval);
  }

  getPitch() {
    this.analyserNode?.getFloatTimeDomainData(this.buffer);
    const pitch = autoCorrelate(
      this.buffer,
      this.context?.sampleRate || this.sampleRate,
    );
    const note = noteFromPitch(pitch);
    return { pitch, note };
  }

  getVolume() {
    this.analyserNode?.getByteFrequencyData(this.intBuffer);
    return (
      this.intBuffer.reduce((cum, cur) => cum + cur, 0) /
      (this.sampleRate / 2) /
      127
    );
  }

  constructor(sampleRate = 2048) {
    this.context = new AudioContext();
    this.source = null;
    this.analyserNode = null;
    this.sampleRate = sampleRate;
    this.buffer = new Float32Array(sampleRate);
    this.intBuffer = new Uint8Array(sampleRate / 2);
  }
}

/**
 * Calculates the auto-correlation of an input buffer.
 *
 * @param {Float32Array} buffer - The input buffer.
 * @param {number} sampleRate - The sample rate of the audio signal.
 * @returns {number} The calculated auto-correlation.
 *   Returns -1 if there is not enough signal.
 */
const autoCorrelate = (buffer: Float32Array, sampleRate: number): number => {
  let bufferSize = buffer.length;
  let rootMeanSquare = 0;

  // Calculate the Root Mean Square (RMS)
  for (let i = 0; i < bufferSize; i++) rootMeanSquare += buffer[i] * buffer[i];

  rootMeanSquare = Math.sqrt(rootMeanSquare / bufferSize);

  // Check if the signal is too weak
  if (rootMeanSquare < 0.01) return -1;

  let lowerBound = 0,
    upperBound = bufferSize - 1;
  const threshold = 0.2;

  // Determine the lower and upper bounds based on the threshold
  for (let i = 0; i < bufferSize / 2; i++)
    if (Math.abs(buffer[i]) < threshold) {
      lowerBound = i;
      break;
    }

  for (let i = 1; i < bufferSize / 2; i++)
    if (Math.abs(buffer[bufferSize - i]) < threshold) {
      upperBound = bufferSize - i;
      break;
    }

  // Trim the buffer to the relevant range
  buffer = buffer.slice(lowerBound, upperBound);
  bufferSize = buffer.length;

  const autoCorrelation = new Array(bufferSize).fill(0);

  // Calculate the autocorrelation values
  for (let i = 0; i < bufferSize; i++)
    for (let j = 0; j < bufferSize - i; j++)
      autoCorrelation[i] += buffer[j] * buffer[j + i];

  // Find the first dip in the autocorrelation function
  let index = 0;
  while (autoCorrelation[index] > autoCorrelation[index + 1]) index++;

  // Find the highest peak in the autocorrelation function
  let maximumValue = -1,
    maximumPosition = -1;
  for (let i = index; i < bufferSize; i++)
    if (autoCorrelation[i] > maximumValue) {
      maximumValue = autoCorrelation[i];
      maximumPosition = i;
    }

  let period = maximumPosition;

  // Parabolic interpolation for fine adjustment
  const previousValue = autoCorrelation[period - 1],
    currentValue = autoCorrelation[period],
    nextValue = autoCorrelation[period + 1];
  const coefficientA = (previousValue + nextValue - 2 * currentValue) / 2;
  const coefficientB = (nextValue - previousValue) / 2;
  if (coefficientA) period = period - coefficientB / (2 * coefficientA);

  // Calculate the frequency
  return sampleRate / period;
};

const noteStrings = [
  "C",
  "C#",
  "D",
  "D#",
  "E",
  "F",
  "F#",
  "G",
  "G#",
  "A",
  "A#",
  "B",
];

const noteFromPitch = (frequency: number) => {
  const noteNum = 12 * (Math.log(frequency / 440) / Math.log(2));
  const idx = Math.round(noteNum) + 69;
  return noteStrings[idx % 12];
};
