


































































































import Vue from "vue";
import { Action } from "vuex-class";
import api from "@/core/utils/api";
import { Recording } from "@/core/models";
import { ImageSlider } from "@/components/common";
import { Component, Prop, PropSync, Watch } from "vue-property-decorator";
import VideoPlayer from "@/components/common/VideoPlayer.vue";

import Plyr from "plyr";
import "plyr/dist/plyr.css";

@Component({ components: { ImageSlider, VideoPlayer } })
export default class RecordingDialog extends Vue {
  @Prop({ default: () => ({} as Recording) }) recording!: Recording;
  @Action("sessions/addSession") addSession!: (d: any) => void;
  @Action("displaySnackbar") displaySnackbar!: (m: string) => void;
  @PropSync("dialogProp", { default: () => false, type: Boolean })
  dialog!: boolean;
  uploading = false;
  shouldStop = false;

  get title() {
    return this.recording.title;
  }
  get hasTimestamps() {
    return this.recording.timestamps && this.recording.timestamps.length > 0;
  }
  get images() {
    if (!this.hasTimestamps) return [];
    return this.recording.timestamps!.map(x => x.slideURI);
  }

  async createSession(initial: boolean = false) {
    this.uploading = true;
    try {
      const end = "/api/Recordings/CreateSession";
      const device = { id: navigator.appCodeName, type: navigator.userAgent };
      const data = {
        isInitial: initial,
        id: this.recording.id,
        device: JSON.stringify(device),
      };
      const sess = (await api.post(end, data)) as any;
      if (this.recording.type === "audio") {
        this.addSession(sess);
        const message = this.$t("rec.succ").toString();
        this.displaySnackbar(message);
      } else {
        const message = this.$t("rec.fromVideo").toString();
        this.displaySnackbar(message);
      }
    } catch (error) {
      const message = error.description || this.$t("rec.fail").toString();
      this.displaySnackbar(message);
      console.log(error);
    }
    this.uploading = false;
    this.dialog = false;
  }

  // Player
  hasAttached = false;
  plyr: Plyr | null = null;
  attachPlayer() {
    const id = `#rec-plyr-${this.recording.id}`;
    this.plyr = new Plyr(id, { invertTime: false, volume: 0.5 });
  }
  close() {
    if (this.recording.type === "audio")
      if (!this.plyr) {
        this.attachPlayer();
        this.$nextTick(() => this.plyr!.stop());
      } else this.plyr.stop();
    else if (this.recording.type === "video") this.shouldStop = true;

    this.dialog = false;
    this.dialog = false;
  }
  @Watch("recording")
  recordingChanged() {
    if (this.recording.type === "audio") this.attachPlayer();
  }
  @Watch("dialog")
  dialogToggled() {
    if (this.dialog && !this.hasAttached && this.recording.type === "audio") {
      this.$nextTick(() => this.attachPlayer());
      this.hasAttached = true;
    }
  }

  // Edit name
  newName = "";
  editingName = false;
  renameLoading = false;
  toggleEditName() {
    if (!this.editingName) {
      this.editingName = true;
      this.newName = this.title;
    } else this.editName();
  }
  async editName() {
    this.renameLoading = true;
    try {
      const end = "/api/Recordings";
      const data = {
        title: this.newName,
        id: this.recording.id,
      };
      await api.put(end, data);
      this.$emit("edit", data);
    } catch (error) {
      console.log(error);
    }
    this.renameLoading = false;
    this.editingName = false;
  }

  // Edit locale
  editingLocale = false;
  newLocale = this.recording.locale;
  langItems = [
    { text: "English", value: "en" },
    { text: "Deutsch", value: "de" },
  ];
  async editLocale() {
    this.editingLocale = true;
    try {
      const end = "/api/Recordings";
      const data = {
        locale: this.newLocale,
        id: this.recording.id,
      };
      await api.put(end, data);
      this.$emit("edit", data);
    } catch (error) {
      console.log(error);
    }
    this.editingLocale = false;
  }
}
