import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VBtn,{attrs:{"color":_vm.selected ? 'primary' : 'transparent',"elevation":_vm.selected ? '0' : '2',"text":_vm.selected,"disabled":_vm.disabled,"loading":_vm.loading},on:{"click":_vm.openSelect}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-presentation")]),_c('span',[_vm._v(_vm._s(_vm.name))])],1),_c(VDialog,{attrs:{"max-width":"600px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,[_vm._v(_vm._s(_vm.$t("upsess.select")))]),_c(VCardText,[_c(VList,_vm._l((_vm.items),function(item,i){return _c(VListItem,{key:i,on:{"click":function($event){return _vm.selectPresentation(item.value)}}},[_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(item.value ? item.name : _vm.$t("global.none")))])],1)],1)}),1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }