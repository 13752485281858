import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{class:("d-flex flex-column " + (_vm.VRMode ? 'transparent' : '')),attrs:{"elevation":"0","height":"100%"}},[_c(VCardTitle,[_c('div',{class:("headline font-weight-bold text-truncate flex-1 " + (_vm.VRMode ? 'white--text' : ''))},[_c('span',[_vm._v(_vm._s(_vm.headline))])]),(!_vm.VRMode)?_c('div',{staticClass:"mx-2"}):_vm._e(),(!_vm.VRMode)?_c('HowToPopup',{attrs:{"id":5}}):_vm._e(),(!_vm.VRMode)?_c('div',{staticClass:"mx-2"}):_vm._e(),(!_vm.VRMode)?_c(VTooltip,{attrs:{"top":"","max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c(VIcon,_vm._g({attrs:{"dark":"","size":"30","color":"primary"}},on),[_vm._v(" mdi-information ")])]}}],null,false,1437643938)},[_c('div',[_c('div',{staticClass:"font-weight-bold text-subtitle-1"},[_vm._v(_vm._s(_vm.ititle))]),_c('div',[_vm._v(_vm._s(_vm.itext))])])]):_vm._e()],1),_c(VCardText,{staticClass:"flex-1 d-flex flex-column justify-center align-center"},[_c('div',{staticClass:"d-flex flex-column justify-center align-center",staticStyle:{"padding":"40px 60px","flex":"1"}},[_c('div',{staticClass:"text-h2 font-weight-bold primary--text"},[_vm._v(" "+_vm._s(_vm.wordsPerMinute)+" ")]),_c('div',{class:("font-weight-bold text-h6 " + (_vm.VRMode ? 'white--text' : 'accentText--text'))},[_c('span',[_vm._v(_vm._s(_vm.$t("cards.wpm")))])])]),_c(VDivider,{class:("my-4 " + (_vm.VRMode ? 'white' : 'darkgray')),staticStyle:{"width":"50%"}}),(!_vm.VRMode)?_c('div',{staticClass:"text-center"},[_c('span',{staticClass:"text-body-1"},[_vm._v(" "+_vm._s(_vm.$t("sessionsPage.sessionsListSection.speechSpeedCard.wpmNotice"))+" ")])]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }